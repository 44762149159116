.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.text{
  margin: 6rem 0 3.5rem 0;
}
.text >span{
  font-weight: 400;
  font-size: 24px;
  line-height: 2;
  display: block;
  text-align: center;
}
.link{
  border: 1px solid var(--charcoal-gray);
  font-size: 1.8rem;
  background-color: #fff;
  border-radius: 4px;
  padding:0.5rem 1rem;
  line-height: 1;
  
}